import React, { useEffect, useState } from 'react';
import { useIslogged } from '../../customHooks/useIslogged';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import './EditProfile.scss';
import { getProfileData, getProfileDataAdditional, updateProfileData, updateAdditionalProfileData } from '../../services/editProfile';
import { updateStorage } from '../../customHooks/handlePersistence';



const EditProfile = ({
  match: {
    params: { subsidiary },
  },
  history,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);



  const [errors, setErrors] = useState({});

  const [dataProfile, setDataProfile] = useState({
    nombres: '',
    apellidos: '',
    telefonoParticular: '',
    eMailParticular: '',
  });

  const [dataAdditional, setDataAdditional] = useState({
    solicitantenombre: '',
    solicitanteemail: '',
    solicitantephone: ''
  })

  const [originalDataProfile, setOriginalDataProfile] = useState(null);
  const [originalDataAdditional, setOriginalDataAdditional] = useState(null);

  const [isEdit, setISEdit] = useState(false);

  const getCurrentRut = () => JSON.parse(sessionStorage.getItem('username'));


  const isAdditional = () => JSON.parse(sessionStorage.getItem('isAdditional'));
  const isOwner = () => JSON.parse(sessionStorage.getItem('isOwner'));

  const getDataProfile = () => {
    const rut = getCurrentRut();
    if (isOwner()) {
      getProfileData(
        rut,
        (response) => {
          const { nombres, apellidos, telefonoParticular, eMailParticular } = response.data;
          const profileData = { nombres, apellidos, telefonoParticular, eMailParticular };
          setDataProfile(profileData);
          setOriginalDataProfile(profileData); 
        },
        (error) => {
          console.error(error);
        }
      );
    }
    if (isAdditional()) {
      getProfileDataAdditional(
        rut,
        (response) => {
          const { solicitantenombre, solicitanteemail, solicitantephone } = response.data[0];
          const additionalData = { solicitantenombre, solicitanteemail, solicitantephone };
          setDataAdditional(additionalData);
          setOriginalDataAdditional(additionalData); 
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };
  

  const handleCancel = () => {
    setISEdit(false); 
    setErrors({});
    if (isOwner()) {
      setDataProfile(originalDataProfile); 
    }
    if (isAdditional()) {
      setDataAdditional(originalDataAdditional); 
    }
  };



  useEffect(() => {
    getDataProfile();
  }, []);

  const handleDisabled = () => setISEdit(!isEdit);

  const keyMapping = {
    nombres: "solicitantenombre",
    eMailParticular: "solicitanteemail",
    telefonoParticular: "solicitantephone"
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    
    if (isAdditional()) {
      
  
      setDataAdditional((prev) => ({
        ...prev,
        [keyMapping[id]]: value,
      }));
    } else {
      setDataProfile((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!validateFields()) return;

      try {
          const rut = getCurrentRut().toString(); 

          let fullName;
          let response = null;

          if (isAdditional()) {
            const {solicitantenombre, solicitanteemail, solicitantephone} = dataAdditional;
            
            fullName = solicitantenombre;
            response = await updateAdditionalProfileData(
                rut,
                solicitantenombre,
                solicitanteemail,
                solicitantephone
            );

          } 
          
          if(isOwner()) {
            const { nombres, apellidos, telefonoParticular, eMailParticular } = dataProfile;
            fullName = `${nombres.trim()} ${apellidos.trim()}`;
            response = await updateProfileData(
                rut,
                nombres,
                apellidos,
                telefonoParticular,
                eMailParticular
            );
          }

          if (response) {
            updateStorage({ fullName });

            if(isOwner()) {
              history.push(`/${subsidiary}/Home`);
              window.location.reload();
            }
            if(isAdditional()){
              history.push(`/${subsidiary}/Home/PostventaAdicional`);
              window.location.reload();
            }
          }

      } catch (error) {
          console.error(error);
      }
  };


  const validateFields = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z\s]+$/;
    const phoneRegex = /^\d{9}$/;
  
    if (isOwner()) {
      if (!dataProfile.nombres.trim() || !nameRegex.test(dataProfile.nombres)) {
        newErrors.nombres = 'El nombre debe contener solo letras.';
      }
  
      if (!dataProfile.apellidos.trim() || !nameRegex.test(dataProfile.apellidos)) {
        newErrors.apellidos = 'El apellido debe contener solo letras.';
      }
  
      if (!emailRegex.test(dataProfile.eMailParticular)) {
        newErrors.eMailParticular = 'Debe ingresar un correo electrónico válido.';
      }
  
      if (!phoneRegex.test(dataProfile.telefonoParticular)) {
        newErrors.telefonoParticular = 'El teléfono debe tener exactamente 9 dígitos.';
      }
    }
  
    if (isAdditional()) {
      if (!dataAdditional.solicitantenombre.trim() || !nameRegex.test(dataAdditional.solicitantenombre)) {
        newErrors.nombres = 'El nombre debe contener solo letras.';
      }
  
      if (!emailRegex.test(dataAdditional.solicitanteemail)) {
        newErrors.eMailParticular = 'Debe ingresar un correo electrónico válido.';
      }
  
      if (!phoneRegex.test(dataAdditional.solicitantephone)) {
        newErrors.telefonoParticular = 'El teléfono debe tener exactamente 9 dígitos.';
      }
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  


  const fields = isOwner()
    ? [
        { id: 'nombres', label: 'Nombres', value: dataProfile.nombres },
        { id: 'apellidos', label: 'Apellidos', value: dataProfile.apellidos },
        { id: 'telefonoParticular', label: 'Teléfono Particular', value: dataProfile.telefonoParticular },
        { id: 'eMailParticular', label: 'Correo Electrónico', value: dataProfile.eMailParticular },
      ]
    : [
        { id: 'nombres', label: 'Nombre', value: dataAdditional.solicitantenombre },
        { id: 'telefonoParticular', label: 'Teléfono Particular', value: dataAdditional.solicitantephone },
        { id: 'eMailParticular', label: 'Correo Electrónico', value: dataAdditional.solicitanteemail },
      ];

      return (
        <div className="EditProfile__container">
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="EditProfile__head">
                  <span className="EditProfile__head__avatarLabel">
                    <AccountCircleIcon style={{ width: '60px', height: '60px', color: '95BBDF' }} />
                  </span>
                  <div className="EditProfile__head__name_container">
                    <span className={`EditProfile__head__name_container__name--${subsidiary}`}>
                      Editar Datos Personales
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid container item xs={12} spacing={3}>
                {fields.map((field) => (
                  <Grid item xs={12} sm={6} key={field.id}>
                    <TextField
                      id={field.id}
                      label={field.label}
                      value={field.value}
                      onChange={handleChange}
                      className="EditProfile__data"
                      disabled={!isEdit}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors[field.id]}
                      helperText={errors[field.id]}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container justifyContent="flex-end" item xs={12}>
                {!isEdit ? (
                  <Button
                    className={`EditProfile__button--${subsidiary}`}
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={handleDisabled}
                  >
                    Modificar
                  </Button>
                ) : (
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        className={`EditProfile__button--${subsidiary}`}
                        variant="contained"
                        startIcon={<CancelIcon />}
                        onClick={handleCancel}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={`EditProfile__button__actualizar--${subsidiary}`}
                        variant="contained"
                        startIcon={<SaveIcon />}
                        type="submit"
                      >
                        Actualizar
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      );
};

export default EditProfile;
