import React from 'react';
import IconPropTypes from '../IconPropTypes';

const PlayIcon = ({ className, color }) => (
  <svg width="27" height="27" className={className} viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="2" transform="translate(1 1)">
      <circle cx="12.444" cy="12.444" r="12.444" />
      <path
        strokeLinecap="round"
        d="M9 8.667l7.444 5.778L9 20.222V8.667z"
        transform="translate(1, -2)"
      />
    </g>
  </svg>
);

PlayIcon.propTypes = IconPropTypes;

export default PlayIcon;
