import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@material-ui/core/Grid';
import Swal from 'sweetalert2';
import ProtectedRoute from '../../components/ProtectedRoute';
import Menu from '../../components/Menu';
import Navbar from '../../components/Navbar';

// Constants
import { getLogos } from '../../utils/getLogos';
import { socovesa, socovesaMenu } from '../../constants/filialesNames';

// Screens
import Home from '../../screens/Home';
import ClientMessages from '../../screens/ClientMessages';
import ClientExecutive from '../../screens/ClientExecutive/ClientExecutive';
import ClientAdditional from '../../screens/ClientAdditional/ClientAdditional';
import EditProfile from '../../screens/EditProfile/EditProfile';
import ClientPropertyDetail from '../../screens/ClientPropertyDetail';

import { ClientProvider } from '../../providers/ClientProvider';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { useNotificationList } from '../../customHooks/fetchData';

import './ClientLayout.scss';
import ClientPostVenta from '../../screens/ClientPostVenta/ClientPostVenta';
import { usePropiedadesPostventa } from '../../customHooks/postventa';
import NewSolicitud from '../../components/ProjectListPostVenta/NewSolicitud';
import useAvailablePermissionsBySubsidiary from '../../customHooks/fetchSubsidiaryPermissions';

const ClientLayout = ({
  match: {
    path,
    url,
    params: { subsidiary },
  },
  history,
}) => {
  const checkMessages = () => {
    history.push(`/${subsidiary}/Home/messages`);
  };
  const {
    state: {
      username = '',
      isOwner = false,
      isAdditional = false,
      idAdditional = '',
      isAdmin = false,
    } = {},
  } = useContext(GlobalContext);
  const [readMessages, setReadMessages] = useState(false);
  const { notificationList } = useNotificationList(username, readMessages);
  const { propiedadesPV, propiedadesPVAdicional, propiedadesPVAdmin } = usePropiedadesPostventa(
    username,
    subsidiary,
    idAdditional,
    JSON.parse(isOwner),
    JSON.parse(isAdditional),
    JSON.parse(isAdmin),
  );
  const { availableSubsidiaries } = useAvailablePermissionsBySubsidiary(subsidiary);
  const { logOut } = useContext(GlobalContext);
  const [activePV, setActivePV] = useState(false);
  useEffect(() => {
    setActivePV(propiedadesPV?.length > 0 || propiedadesPV.owners?.length > 0);
  }, [propiedadesPV]);

  const onReadMessages = () => {
    setReadMessages(!readMessages);
  };
  const logOutCallback = () => {
    Swal.fire({
      title: 'Cerrar sesión',
      text: '¿Estás seguro que quieres cerrar sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cerrar sesión',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        logOut(() => {
          history.push(`/${subsidiary}`);
        });
      }
    });
  };
  return (
    <>
      {/* <Loader /> */}
      <Grid container className={`ClientLayout ClientLayout--${subsidiary}`}>
        <Grid item md={3} className="ClientLayout__Menu">
          <div className={`ClientLayout__sideMenu ClientLayout__sideMenu--${subsidiary}`}>
            {subsidiary === socovesa ? (
              <img
                alt="logo"
                className="ClientLayout__sideMenu__logo"
                {...getLogos(socovesaMenu)}
              />
            ) : (
              <img alt="logo" className="ClientLayout__sideMenu__logo" {...getLogos(subsidiary)} />
            )}
            <Menu
              className="ClientLayout__sideMenu__Menu"
              subsidiary={subsidiary}
              activePV={activePV}
              availableSubsidiaries={availableSubsidiaries}
              additional={!!idAdditional}
              isAdmin={isAdmin}
              isOwner={JSON.parse(isOwner)}
              isAdditional={JSON.parse(isAdditional)}
            />
          </div>
        </Grid>
        <Grid item md={9} sm={12} style={{ backgroundColor: 'inherit' }}>
          <Navbar
            subsidiary={subsidiary}
            logOutCallback={logOutCallback}
            messageCallback={checkMessages}
            notificationList={notificationList}
            availableSubsidiaries={availableSubsidiaries}
            urlBase={url}
            isExecutive={false}
            activePV={activePV}
            isOwner={JSON.parse(isOwner)}
            isAdditional={JSON.parse(isAdditional)}
          />
          <div className="ClientLayout__body">
            <ClientProvider>
              <ProtectedRoute
                path={`${path}/`}
                exact
                render={props => (
                  <Home
                    {...props}
                    notificationList={notificationList}
                    availableSubsidiaries={availableSubsidiaries}
                  />
                )}
              />
              <ProtectedRoute
                path={`${path}/:projectId/:propertyId`}
                exact
                render={props => (
                  <ClientPropertyDetail {...props} availableSubsidiaries={availableSubsidiaries} />
                )}
              />
              <ProtectedRoute
                path={`${path}/messages`}
                exact
                render={props => <ClientMessages {...props} onReadMessages={onReadMessages} />}
              />
              <ProtectedRoute path={`${path}/executive`} exact component={ClientExecutive} />
              <ProtectedRoute
                path={`${path}/PostventaAdministrador`}
                exact
                render={props => (
                  <ClientPostVenta
                    {...props}
                    propiedadesPV={propiedadesPVAdmin}
                    additional={false}
                    admin
                  />
                )}
              />
              <ProtectedRoute
                path={`${path}/PostventaAdministrador/solicitud/:unidad`}
                exact
                component={NewSolicitud}
              />
              <ProtectedRoute
                path={`${path}/Postventa`}
                exact
                render={props => (
                  <ClientPostVenta
                    {...props}
                    propiedadesPV={propiedadesPV}
                    additional={false}
                    admin={false}
                  />
                )}
              />
              <ProtectedRoute
                path={`${path}/Postventa/solicitud/:unidad`}
                exact
                component={NewSolicitud}
              />
              <ProtectedRoute
                path={`${path}/PostventaAdicional`}
                exact
                render={props => (
                  <ClientPostVenta
                    {...props}
                    propiedadesPV={propiedadesPVAdicional}
                    additional
                    admin={false}
                  />
                )}
              />
              <ProtectedRoute
                path={`${path}/PostventaAdicional/solicitud/:unidad`}
                exact
                component={NewSolicitud}
              />
              <ProtectedRoute
                path={`${path}/additional`}
                exact
                render={props => <ClientAdditional {...props} propiedadesPV={propiedadesPV} />}
              />
              <ProtectedRoute
                path={`${path}/editProfile`}
                exact
                component={EditProfile}
              />
            </ClientProvider>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

ClientLayout.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default memo(ClientLayout);
