import React, { useContext, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import PropTypes from 'prop-types';
import Button from '../../components/ButtonTypes';
import RightArrow from '../../components/Icons/RightArrowIcon';

// Form
import LoginInnerFormComponent from './LoginInnerForm';

// Store
import { GlobalContext } from '../../providers/GlobalStateProvider';

// Constants
import { getLogos } from '../../utils/getLogos';
import { almagro, socovesa, empresas, pilares } from '../../constants/filialesNames';
import { primary, secondary } from '../../constants/colorTypes';
import {
  white,
  black,
  grey,
  socovesaPrimary,
  almagroPrimary,
  pilaresPrimary,
  empresasPrimary,
} from '../../constants/colors';

// Utils
import { firstLetterUppercase } from '../../utils/utils';

// Enpoints
import { ClientLoginRequest, ExecutiveLoginRequest } from '../../services/authentication';

import './Login.scss';

function getLoginColorConfig(element, subsidiary) {
  const colors = {
    inputs: {
      [socovesa]: primary,
      [empresas]: primary,
      [almagro]: black,
      [pilares]: secondary,
    },
    button: {
      [socovesa]: primary,
      [empresas]: primary,
      [almagro]: primary,
      [pilares]: secondary,
    },
    arrow: {
      [socovesa]: socovesaPrimary,
      [empresas]: empresasPrimary,
      [almagro]: almagroPrimary,
      [pilares]: pilaresPrimary,
    },
  };
  return colors[element][subsidiary];
}

const LoginTheme = subsidiary => {
  const themes = {
    socovesa: {
      overrides: {},
    },
    almagro: {
      overrides: {
        MuiInput: {
          underline: {
            '&:hover:not($disabled):after': {
              borderBottom: `2px solid ${white}`,
            },
            '&:hover:not($disabled):before': {
              borderBottom: `2px solid ${white}`,
            },
            '&:before': {
              borderBottom: `1px solid ${grey}`,
            },
            '&:after': {
              borderBottom: `2px solid ${white}`,
            },
            color: black,
          },
        },
        MuiFormHelperText: {
          root: {
            color: white,
          },
        },
      },
    },
    pilares: {
      overrides: {
        MuiInput: {
          underline: {
            '&:hover:not($disabled):after': {
              borderBottom: `2px solid ${white}`,
            },
            '&:hover:not($disabled):before': {
              borderBottom: `2px solid ${white}`,
            },
            '&:before': {
              borderBottom: `1px solid ${grey}`,
            },
            '&:after': {
              borderBottom: `2px solid ${white}`,
            },
          },
        },
        MuiFormHelperText: {
          root: {
            color: white,
          },
        },
      },
    },
    empresas: {},
  };

  return themes[subsidiary];
};

export const Login = ({
  match: {
    params: { subsidiary },
  },
  history,
  //subsidiary,
}) => {
  const captchaRef = useRef(null);

  const submitLogin = ({ username, password, captchaToken }, updateToken) => {
    const handleRequest = subsidiary === empresas ? ExecutiveLoginRequest : ClientLoginRequest;
    const formatedUsername = subsidiary === empresas ? username : username.replace('-', '');
    handleRequest(
      {
        username: formatedUsername.trim(),
        password,
        subsidiary: subsidiary !== empresas && subsidiary,
        captchaToken:
          process.env.REACT_APP_ENV_NAME === 'local' || process.env.REACT_APP_ENV_NAME === 'dev'
            ? null
            : captchaToken,
      },
      res => {
        const {
          data: {
            token: newToken,
            user: { rut, nombres, apellidos },
            permissions,
          },
        } = res;

        const userId =
          subsidiary === empresas
            ? rut
            : formatedUsername.substring(0, formatedUsername.length - 1);
        const mail = subsidiary === empresas ? username : res.data.user.eMailParticular;
        const idAdditional = subsidiary === empresas ? null : res.data.user.id_Adicional;
        const isOwner = subsidiary === empresas ? false : res.data.user.isOwner;
        const isAdditional = subsidiary === empresas ? false : res.data.user.isAdditional;
        const isAdmin = subsidiary === empresas ? false : res.data.user.isAdmin;
        updateToken(
          newToken,
          userId,
          `${nombres} ${apellidos}`,
          rut,
          subsidiary,
          mail,
          idAdditional,
          isOwner,
          isAdditional,
          isAdmin,
          permissions,
        );
        let redirectPath = `/${subsidiary}/Home`;
        if (!isOwner && isAdmin) {
          redirectPath = `/${subsidiary}/Home/PostventaAdministrador`;
        } else if (!isOwner && isAdditional) {
          redirectPath = `/${subsidiary}/Home/PostventaAdicional`;
        }
        history.push(redirectPath);
      },
      error => {
        console.error({ error });
        if (captchaRef.current) captchaRef.current.reset();
      },
    );
  };

  const { updateToken } = useContext(GlobalContext);


  const title = () => {
    if(subsidiary === 'almagro')
      return `Mi ${firstLetterUppercase(subsidiary)}`
    else{
      return `${firstLetterUppercase(subsidiary)}`
    }
  }

  return (
    <ThemeProvider theme={LoginTheme(subsidiary)}>
      <div className={`Login Login--${subsidiary}`}>
        <img
          alt="logo"
          className={`Login__logo Login__logo--${subsidiary}`}
          {...getLogos(subsidiary)}
        />
        <h5 className={`Login__title--${subsidiary}`}>Iniciar sesión en</h5>


        <h2 className="Login__subtitle">{title()}</h2>

        <LoginInnerFormComponent
          inputColor={getLoginColorConfig('inputs', subsidiary)}
          emailPrimary={subsidiary === empresas}
          onSubmit={values => submitLogin(values, updateToken)}
          captchaRef={captchaRef}
        />
        <Button
          color={getLoginColorConfig('button', subsidiary)}
          variant="text"
          onClick={() => history.push(`/${subsidiary}/ForgotPassword`)}
        >
          ¿Olvidaste tu clave?
          <RightArrow color={getLoginColorConfig('arrow', subsidiary)} />
        </Button>
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  history: PropTypes.object,
};

export default withRouter(Login);
