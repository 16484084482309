/* eslint-disable no-eval */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { withRouter, NavLink } from 'react-router-dom';

// Constants

// Components

import KeyIcon from '@material-ui/icons/VpnKey';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';
import ManageAccountsIcon from '@material-ui/icons/SupervisorAccountTwoTone';
import DescriptionIcon from '@material-ui/icons/Description';
import { socovesa, almagro, pilares, empresas } from '../../constants/filialesNames';
import { white } from '../../constants/colors';

import './Menu.scss';

import { checkSubsidiarysMenus } from '../../services/projectsService';
import { useHasPermission } from '../../customHooks/usePermissions';

// Client Menu
const menuSections = {
  Menu: [
    { name: 'inicio', active: true, id: 'menu1', href: '/Home', icon: HomeIcon },
    { name: 'mensajes', id: 'menu4', href: '/Home/messages', icon: EmailIcon },
    { name: 'EJECUTIVO', id: 'menu5', href: '/Home/executive', icon: AccountCircleIcon },
    { name: 'Usuarios adicionales', id: 'menu6', href: '/Home/additional', icon: GroupAddIcon },
    { name: 'Postventa', id: 'menu7', href: '/Home/Postventa', icon: ContactPhoneIcon },
    {
      name: 'Postventa Adicional',
      id: 'menu8',
      href: '/Home/PostventaAdicional',
      icon: ContactPhoneIcon,
    },
    {
      name: 'Postventa Espacios Comunes',
      id: 'menu9',
      href: '/Home/PostventaAdministrador',
      icon: ApartmentIcon,
    },
    { name: 'Editar Perfil', id: 'menu10', href: '/Home/editProfile', icon:  EditIcon },
  ],
  Herramientas: [
    { name: 'Cambiar contraseña', id: 'herramienta3', href: '/RecoverPassword', icon: KeyIcon },
  ],
};

// Enterprise Menu
const enterpriseMenu = {
  Menu: [
    { name: 'inicio', active: true, id: 'menu1', href: '/Home', icon: HomeIcon },
    { name: 'CLIENTES', id: 'menu2', href: '/Home/clients', icon: AccountCircleIcon },
    { name: 'MIS mensajes', id: 'menu3', href: '/Home/messages', icon: EmailIcon },
  ],
  Herramientas: [
    {
      name: 'Habilitar filiales',
      id: 'herramienta4',
      href: '/Home/subsidiaries',
      icon: ApartmentIcon,
    },
    {
      name: 'PERFIL EJECUTIVOS',
      id: 'herramienta2',
      href: '/Home/executives/list',
      icon: ManageAccountsIcon,
    },
    {
      name: 'Roles y Perfiles',
      id: 'herramienta5',
      href: '/Home/profiles',
      icon: PeopleIcon,
    },
    { name: 'Clasificación de documentos',
      id: 'herramienta6',
      href: '/Home/documents',
      icon: DescriptionIcon,
    },
    { name: 'Cambiar contraseña', 
      id: 'herramienta3', 
      href: '/RecoverPassword', 
      icon: KeyIcon, 
    },
  ],
};

const useMenuSections = subsidiary =>
  useCallback(() => (subsidiary === empresas ? enterpriseMenu : menuSections), [subsidiary]);

const filialColors = {
  [socovesa]: white,
  [almagro]: '#4D4D4D',
  [pilares]: white,
  [empresas]: white,
};

const MenuItem = ({ itemName, className, subsidiary, href, Icono, disabled = false, onClick }) => {
  if (disabled) {
    return null;
  }
  return (
    <NavLink
      exact
      className={className}
      to={`/${subsidiary}${href}`}
      activeClassName="active"
      onClick={onClick}
      // style={{ color: filialColors[subsidiary] }}
    >
      <Icono
        // style={{ color: filialColors[subsidiary] }}
        className="MenuComponent__Container__Section__itemList__item__icon"
      />
      {itemName}
    </NavLink>
  );
};

MenuItem.propTypes = {
  itemName: PropTypes.string,
  className: PropTypes.string,
  subsidiary: PropTypes.string,
  href: PropTypes.string,
  Icono: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

const MenuItemComponent = withRouter(MenuItem);

const MenuSection = ({
  sections: { sections },
  subsidiary,
  activePV,
  isOwner,
  isAdditional,
  isAdministrator,
  onClick,
  menuType,
}) => {
  const [availableSubsidiaries, setAvailableSubsidiaries] = useState({});
  useEffect(() => {
    checkSubsidiarysMenus(
      subsidiary,
      response => {
        setAvailableSubsidiaries(response.data);
      },
      error => {
        console.error(error);
      },
    );
  }, []);

  const shouldDisplayItem = (name, id, menu) => {
    if (menu === enterpriseMenu) return true;
    if (availableSubsidiaries && !availableSubsidiaries.messages && id === 'menu4') {
      return false;
    }
    if (availableSubsidiaries && !availableSubsidiaries.ejecutivo && id === 'menu5') {
      return false;
    }

    if (activePV && availableSubsidiaries && availableSubsidiaries.additional && id === 'menu6') {
      return true;
    }
    if (
      eval(isAdministrator) &&
      availableSubsidiaries &&
      !availableSubsidiaries.admin &&
      id === 'menu9'
    ) {
      return false;
    }
    if (eval(isAdministrator) && id === 'menu9') {
      return true;
    }
    if (eval(isAdditional) && (id === 'menu8' || id === 'menu10')) {
      return true;
    }
    if ((id === 'menu7' || id === 'menu6') && !eval(activePV)) {
      return false;
    }
    if (eval(isOwner) && id !== 'menu6' && id !== 'menu8' && id !== 'menu9') {
      return true;
    }
    if (!eval(isOwner) && id !== 'menu8' && id !== 'menu9') {
      return false;
    }
    return false;
  };
  const permissions = useHasPermission('intranet', null);

  const shouldDisplayTool = name => {
    if (subsidiary === 'empresas') {
      if (name === 'Cambiar contraseña') {
        return true;
      }
      // if (name === 'Habilitar filiales' && !!permissions['Habilitar Filiales']) {
      if (name === 'Habilitar filiales') {
        return true;
      }
      // if (name === 'PERFIL EJECUTIVOS' && !!permissions['Perfil Ejecutivos']) {
      if (name === 'PERFIL EJECUTIVOS') {
        return true;
      }
      if (name === 'Roles y Perfiles') { //TODO: add permissions to roles
        return true;
      }
      if (name === 'Clasificación de documentos') {
        return true;
      }
      return false;
    }
    return true;
  };
  return Object.keys(sections).map((sectionName, i) => (
    <Fragment key={`f${i}`}>
      <span
        className={`MenuComponent__Container__Section__name MenuComponent__Container__Section__name--${subsidiary}`}
        key={`name${i}`}
      >
        {sectionName}
      </span>
      <ul className="MenuComponent__Container__Section__itemList" key={`list${i}`}>
        {sections[sectionName].map(({ name, id, href, icon }) => {
          if (sectionName === 'Herramientas') {
            const disabledTool = !shouldDisplayTool(name);
            return (
              <MenuItemComponent
                key={id}
                itemName={name}
                className={`MenuComponent__Container__Section__itemList__item MenuComponent__Container__Section__itemList__item--${subsidiary}`}
                subsidiary={subsidiary}
                disabled={disabledTool}
                href={href}
                Icono={icon}
                onClick={onClick}
              />
            );
          }
          let disabled = true;
          if (shouldDisplayItem(name, id, menuType)) {
            disabled = false;
          }
          return (
            <MenuItemComponent
              key={id}
              itemName={name}
              className={`MenuComponent__Container__Section__itemList__item MenuComponent__Container__Section__itemList__item--${subsidiary}`}
              subsidiary={subsidiary}
              href={href}
              disabled={disabled}
              Icono={icon}
              onClick={onClick}
              availableSubsidiaries={availableSubsidiaries}
            />
          );
        })}
      </ul>
    </Fragment>
  ));
};

const SyncProps = propSections => {
  const [sections, setSections] = useState({ ...propSections });
  useEffect(() => {
    setSections({ ...propSections });
  }, [propSections]);
  return { sections, setSections };
};

const MenuComponent = ({
  className,
  subsidiary,
  activePV,
  isOwner,
  isAdditional,
  isAdmin,
  onClick,
}) => {
  const { sections } = SyncProps(useMenuSections(subsidiary)());
  const menuType = useMenuSections(subsidiary)();
  return (
    <div className={`MenuComponent ${className}`}>
      <div className="MenuComponent__Container">
        <MenuSection
          sections={{ sections }}
          subsidiary={subsidiary}
          activePV={activePV}
          isOwner={isOwner}
          isAdditional={isAdditional}
          isAdministrator={isAdmin}
          onClick={onClick}
          menuType={menuType}
        />
      </div>
    </div>
  );
};

MenuComponent.propTypes = {
  className: PropTypes.string,
  subsidiary: PropTypes.string,
  activePV: PropTypes.bool,
  isOwner: PropTypes.bool,
  isAdditional: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func,
  availableSubsidiaries: PropTypes.object,
};

export default MenuComponent;
