/* eslint-disable quotes */
import React, { useState, Fragment, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import RecoverPasswordInnerForm from './RecoverPasswordInnerForm';
import PassIcon from '../../components/Icons/PassIcon';
import Button from '../../components/ButtonTypes';

// Constants
import { getLogos } from '../../utils/getLogos';
import { black, white } from '../../constants/colors';
import { almagro } from '../../constants/filialesNames';

import { RecoverPassRequest, RestablecerPassRequest } from '../../services/authentication';

import './RecoverPassword.scss';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { useIslogged } from '../../customHooks/useIslogged';

function SubmitPassword(token, { password }, setPasswordSubmited) {
  RestablecerPassRequest(
    token,
    password,
    () => {
      setPasswordSubmited(true);
    },
    () => {
      setPasswordSubmited(false);
    },
  );
}

function goToLogin(history, subsidiary, path = 'Home') {
  history.push(`/${subsidiary}/${path}`);
}

const RecoverPassword = ({
  match: {
    params: { subsidiary, token },
  },
  history,
}) => {
  const {
    state: { email, fullName, idAdditional },
  } = useContext(GlobalContext);
  const [isLogged, setIsLogged] = useState(useIslogged());

  if (!email && !token) goToLogin(history, subsidiary, '');

  useEffect(() => {
    setToken(token || '');
    if (!token) {
      RecoverPassRequest({ subsidiary, email, idAdditional, send_mail: false }, res => {
        return res.data.token;
      }).then(res => {
        setToken(res);
        setMessageButton('VOLVER');
      });
    }
  }, [email, token]);
  const [userName] = useState(fullName || '');
  const [useToken, setToken] = useState(token || '');
  const [passwordSubmited, setPasswordSubmited] = useState(false);
  const [messageButton, setMessageButton] = useState('INICIAR SESIÓN');


  const title = () => {
    if(subsidiary === 'almagro') {
      return 'Mi Almagro'
    }
    else{
      return subsidiary;
    }
  }

  return (
    <div className={`RecoverPassword RecoverPassword--${subsidiary}`}>
      <div className="RecoverPassword__container">
        <img alt="logo" className="RecoverPassword__container__logo" {...getLogos(subsidiary)} />
        {!passwordSubmited ? (
          <Fragment>
            <h1 className="RecoverPassword__container__title">
              Reestablecer clave de acceso a{' '}
              {title()}
            </h1>
            <span>¡Hola {userName}!</span>
            <span>Escribe una nueva clave de acceso.</span>
            <RecoverPasswordInnerForm
              onSubmit={values => SubmitPassword(useToken, values, setPasswordSubmited)}
              onCancel={history.goBack}
            />
          </Fragment>
        ) : (
          <div className="RecoverPassword__success">
            <PassIcon
              className="RecoverPassword__success__icon"
              color={subsidiary === almagro ? white : black}
              secondaryColor={subsidiary === almagro ? black : white}
            />
            <span className="RecoverPassword__success__span">Clave de acceso</span>
            <span className="RecoverPassword__success__span">cambiada con éxito</span>
            <Button onClick={() => goToLogin(history, subsidiary, isLogged ? 'Home' : '')}>
              {messageButton}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

RecoverPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subsidiary: PropTypes.string,
      token: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default RecoverPassword;
