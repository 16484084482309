import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { checkAvailableMenus, modifyAvailableMenus } from '../../services/projectsService';
import { getItemSessionStorage } from '../../customHooks/handlePersistence';
// Component
import SubsidiaryCard from '../../components/SubsidiaryCard';

import './SubsidiaryScreenList.scss';

const SUBSIDIARIES_CODE = {
  pilares: 5,
  almagro: 1,
  socovesa: [3, 2],
};

const stateToApiFormat = (subsidiaryName, state) => {
  const codes = SUBSIDIARIES_CODE[subsidiaryName];
  const { admin, additional, messages, documentsPV, ejecutivo } = state;

  // if socovesa return an array of objects
  if (Array.isArray(codes)) {
    return codes.map(code => ({
      CodigoFilial: code,
      VBADicionalenFrontCliente: additional ? 1 : 0,
      VBAdminenFrontCliente: admin ? 1 : 0,
      VBMensajeenFrontCliente: messages ? 1 : 0,
      vBmisdocumentosPV: documentsPV ? 1 : 0,
      vBejecutivo: ejecutivo ? 1 : 0,
    }));
  }
  return [
    {
      CodigoFilial: codes,
      VBADicionalenFrontCliente: additional ? 1 : 0,
      VBAdminenFrontCliente: admin ? 1 : 0,
      VBMensajeenFrontCliente: messages ? 1 : 0,
      vBmisdocumentosPV: documentsPV ? 1 : 0,
      vBejecutivo: ejecutivo ? 1 : 0,
    },
  ];
};

const SubsidiaryScreenList = () => {
  const [data, setData] = useState({});
  const [userId, setUserId] = useState();
  const [mostrarComponente, setMostrarComponente] = useState(false);

  useEffect(() => {
    setUserId(getItemSessionStorage('username'));
  }, []);

  useEffect(() => {
    setMostrarComponente([9656526, 26453910].includes(userId));
  }, [userId]);

  useEffect(() => {
    checkAvailableMenus(
      response => {
        setData(response.data);
      },
      error => {
        console.error(error);
      },
    );
  }, []);

  const handleUpdate = (subsidiaryName, updatedData) => {
    const apiDataArray = stateToApiFormat(subsidiaryName, updatedData);

    apiDataArray.forEach(apiData => {
      modifyAvailableMenus(
        subsidiaryName,
        apiData,
        () => {
          setData(prevState => ({
            ...prevState,
            [subsidiaryName]: updatedData,
          }));
        },
        error => {
          console.error(error);
        },
      );
    });
  };


  return (
    <div className="SubsidiaryScreenList">
      {mostrarComponente ? (
      <>
        <Typography variant="h2" className="SubsidiaryScreenList__title">
          PERMISOS HABILITADOS
        </Typography>
        {Object.entries(data).map(([key, value], index) => (
          <SubsidiaryCard key={index} data={{ [key]: value }} onUpdate={handleUpdate} />
        ))}
      </>
    ) : (
      <Typography variant="h2" className="SubsidiaryScreenList__title">
        NO TIENE PERMISOS PARA VER ESTA PÁGINA
      </Typography>
    )}
    </div>
    );
};
export default SubsidiaryScreenList;
